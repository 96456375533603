import request from '@/utils/request'

/**
 * 保存或更新区域
 * @param form
 */
export function saveOrUpdate(form) {
  return request({
    url: `/wms-web/area/saveOrUpdate`,
    method: 'post',
    data: form
  })
}

/**
 * 通过id获取区域
 * 详情
 * @param areaCode
 */
export function getArea(areaCode) {
  return request({
    url: `/wms-web/area/getArea`,
    method: 'get',
    params: {areaCode}
  })
}

/**
 * 判断分仓代码是否存在
 * @param areaCode
 */
export function findArea(areaCode) {
  return request({
    url: `/wms-web/area/find`,
    method: 'get',
    params: {areaCode}
  })
}

/**
 * 判断分仓名称是否存在
 * @param areaCode
 */
export function findAreaName({areaCode, areaName}) {
  return request({
    url: `/wms-web/area/findByAreaName?areaCode=${areaCode}&areaName=${areaName}`,
    method: 'post',
  })
}

/**
 * 获取分仓列表
 */
export function list() {
  return request({
    url: `/wms-web/area/list`,
    method: 'post',
  })
}

/**
 * 分仓新增
 */
export function saveArea(data) {
  return request({
    url: `/wms-web/area/save`,
    method: 'post',
    data
  })
}

/**
 * 分仓修改
 */
export function updateArea(data) {
  return request({
    url: `/wms-web/area/update`,
    method: 'post',
    data
  })
}

/**
 * 所有供货仓
 */
export function getSupplyArea(data) {
  return request({
    url: `/wms-web/area/getSupplyArea`,
    method: 'post',
    data
  })
}

/**
 * 登陆仓库下属供货仓
 */
export function getSupplyAreaByAreaCode(data) {
  return request({
    url: `/wms-web/area/getSupplyAreaByAreaCode`,
    method: 'post',
    data
  })
}

/**
 * 交投逻辑仓
 */
export function bindAreaCode(data) {
  return request({
    url: `/wms-web/area/bindAreaCode`,
    method: 'post',
    data
  })
}

/**
 * 登陆仓库主图
 */
export function domainGetLoginImg(data) {
  return request({
    url: `/wms-web/area/domainGetLoginImg`,
    method: 'post',
    data
  })
}

/**
 * 分仓详情
 */
export function areaDetail(data) {
  return request({
    url: `/wms-web/area/detail`,
    method: 'post',
    data
  })
}

/**
 * 设备分仓
 */
export function areaGetList(data) {
  return request({
    url: `/wms-web/area/getList`,
    method: 'post',
    data
  })
}

/**
 * 工位码下拉
 */
export function jobAreaGetList(data) {
  return request({
    url: `/wms-web/basJobArea/getJobAreaListByType`,
    method: 'post',
    data
  })
}

/**
 * 暂存码下拉
 */
export function jobAreaLocationGetList(data) {
  return request({
    url: `/wms-web/basJobAreaTempLocation/getListByJobAreaId`,
    method: 'post',
    data
  })
}

/**
 * 鲜食初始化
 */
export function reservoirInitialize(data) {
  return request({
    url: `/wms-web/area/reservoirInitialize`,
    method: 'post',
    data
  })
}

//手动截单
export function collectCross(data) {
  return request({
      url: `/wms-web/fresh/order/notify/header/collectCross`,
      method: 'post',
      data
  })
}

export function collectZjCross(data) {
  return request({
      url: `/wms-web/fresh/order/notify/header/collect/zj/cross`,
      method: 'post',
      data
  })
}

//上游分仓详情
export function getUpstreamSku(data,lastAreaCurrent) {
  return request({
      url: `/wms-web/area/getUpstreamSku/${lastAreaCurrent.pages}/${lastAreaCurrent.size}`,
      method: 'post',
      data
  })
}


//上游仓库更新加删除
export const saveOrdelUpstreamSku = (data) => {
  return request({
    url: `/wms-web/area/saveUpstreamSku`,
    method: 'post',
    data
  })
}
//实物批号来源编码
export const getReservoirsByApi = (data) => {
  return request({
    url: `/wms-web/area/checked/batchSourceCode`,
    method: 'post',
    data
  })
}

  /**
 *  图片列表 获取数据
 * @param form
 */
  export function getListImg(form) {
    return request({
      url: `/wms-web/file/getList`,
      method: 'post',
      data: form
    })
  }