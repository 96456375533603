import request from '@/utils/request'


/**
 * 车型下拉
 * @param data
 */
export function getStyleCar(data) {
  return request({
    url: `/wms-web/basDeliveryStrategyCar/getCarList`,
    method: 'post',
    data: data
  })
}
/**
 * 所有车型下拉
 * @param data
 */
export function getStyleCarAll(data) {
  return request({
    url: `/wms-web/basDeliveryStrategyCar/getCarListAll`,
    method: 'post',
    data: data
  })
}
/**
 * 预约时间段下拉
 * @param data
 */
export function getPlanTime(data) {
    return request({
      url: `/wms-web/docDeliveryReservation/supplier/getStrategyTimeList`,
      method: 'post',
      data: data
    })
  }
  
  /**
 * 获取发货单号
 * @param data
 */
  export function getPurchase(data) {
    return request({
      url: `/wms-web/docDeliveryReservation/supplier/purchase/list`,
      method: 'post',
      data: data
    })
  }

    /**
 * 新增
 * @param data
 */
    export function save(data) {
        return request({
          url: `/wms-web/docDeliveryReservation/supplier/save`,
          method: 'post',
          data: data
        })
      }

//取消
      export function cancelApi(data) {
        return request({
          url: `/wms-web/docDeliveryReservation/cancel`,
          method: 'post',
          data: data
        })
      }
      

      