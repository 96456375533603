import { getStyleCar,getPlanTime,getStyleCarAll } from '@/api/supplier/docDeliveryReservation'

const reservation = {
  state: {
    styleCarList: [],
    planTimeList: [],
    styleCarAllList: []
  },
  actions: {
    getStyleCar({commit, state},areaCode = null) {
      if (state.styleCarList.length === 0) {
        getStyleCar({areaCode:areaCode}).then(res => {
          commit('setStyleCar', res.data);
        }).catch(err => {
          console.log(err)
        })
      }
    },
    getStyleCarAll({commit, state}) {
      if (state.styleCarAllList.length === 0) {
        getStyleCarAll({}).then(res => {
          commit('setStyleCarAll', res.data);
        }).catch(err => {
          console.log(err)
        })
      }
    },
    resetStyleCar({commit, state},areaCode = null) {
        getStyleCar({areaCode:areaCode}).then(res => {
        commit('setStyleCar', res.data);
      }).catch(err => {
        console.log(err)
      })
    },
    getPlanTime({commit, state},params= {}) {
        if (state.planTimeList.length === 0) {
          getPlanTime(params).then(res => {
            commit('setPlanTime', res.data.map(item => {
                return {
                    ...item,
                    strategyTimeLabel: item.startTime+ '|' + item.endTime
                }
            }));
          }).catch(err => {
            commit('setPlanTime', [])
            console.log(err)
          })
        }
      },
      resetPlanTime({commit, state},params= {}) {
          getPlanTime(params).then(res => {
          commit('setPlanTime', res.data.map(item => {
            return {
                ...item,
                strategyTimeLabel: item.startTime+ '-' + item.endTime
            }
        }));
        }).catch(err => {
            commit('setPlanTime', [])
          console.log(err)
        })
      },

  },
  mutations: {
    setStyleCar: (state, data) => {
      state.styleCarList = data
    },
    setPlanTime: (state, data) => {
        state.planTimeList = data
      },
      setStyleCarAll: (state, data) => {
        state.styleCarAllList = data
      },
  }
};

export default reservation